@if (product) {
  <main class="clickable" tabindex="0" (click)="selectProduct()">
    @if (type === 'column') {
      <div
        class="justify-content-center bg-white lg-radius shadow-sm position-relative overflow-hidden cardSize">
        <div class="col-12 h-100 d-flex flex-column">
          <!-- NOTE :: MAKE OR REVOKE FAVORITE SECTION -->
          <div class="row">
            <div class="position-relative">
              @if (!disabledFavoriteAction) {
                <section
                  class="position-absolute bg-white rounded-circle border z-3 end-0 me-3 mt-2 p-1 d-flex px-2"
                  role="button"
                  tabindex="2">
                  @if (!isLoading && product) {
                    @if (product.is_favorite_product) {
                      <span
                        type="button"
                        (click)="makeFavoriteProducts(product, $event)"
                        tabindex="0"
                        title="Revoke Favorite">
                        <span class="text-primary"
                          ><i class="fa-solid fa-star"></i
                        ></span>
                      </span>
                    } @else if (!product.is_favorite_product) {
                      <span
                        type="button"
                        (click)="makeFavoriteProducts(product, $event)"
                        tabindex="0"
                        title="Make Favorite">
                        <span class="text-primary"
                          ><i class="fa-regular fa-star"></i
                        ></span>
                      </span>
                    }
                  } @else {
                    <span class="text-primary"
                      ><i class="fas fa-spinner fa-pulse"></i
                    ></span>
                  }
                </section>
              }
              @if (product.lookup_image) {
                <div>
                  <img
                    [src]="product.lookup_image"
                    alt="{{ product.lookup_title }} image" />
                </div>
              } @else if (product && !product.lookup_image) {
                <div class="bg-secondary">
                  <span class="d-flex justify-content-center">
                    <i
                      class="fa-solid fa-mountain-sun fa-3x my-5 text-secondary"></i>
                  </span>
                </div>
              }
              <!-- percentage-label -->
              @if (product.payless_client_rate) {
                <div
                  class="percentage-label d-flex justify-content-center align-items-center text-all-white bg-primary sm-x-small position-absolute">
                  - {{ product.payless_client_rate | number }} %
                </div>
              }
              <!-- end of percentage-label -->
              <!-- NOTE :: Price -->
              @if (product.price) {
                <span
                  class="position-absolute fs-small px-1 text-primary bottom-0 start-1"
                  [ngClass]="
                    currentMode === 'light' ? 'priceBGLight' : 'priceBGDark'
                  ">
                  <app-amount-visibility
                    [alwaysShowAmount]="true"
                    [amount]="product.price"
                    [currency]="'BIF'"></app-amount-visibility>
                </span>
              }
            </div>
          </div>

          <div
            class="d-flex flex-column justify-content-end row flex-grow-1 overflow-hidden position-relative p-1">
            <div class="ms-0 d-flex flex-row justify-content-between">
              <div
                class="col-11 text-truncate"
                title="{{ product.lookup_title }}">
                <span class="text-secondary font-calibri fs-normal mb-1">
                  {{ product.lookup_title }}
                </span>
              </div>
              <div class="col-3"></div>
            </div>
            <div
              class="position-absolute bg-primary rounded-circle redConer"></div>
            <div class="ms-0 d-flex flex-row justify-content-between mt-1">
              <div class="col-8 text-truncate">
                <span class="text-secondary fs-small font-calibri">
                  {{ product.lookup_subtitle }}
                </span>
              </div>
              <div class="col-2">
                <i class="fa-solid fa-cart-arrow-down fa-lg text-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    @if (type === 'row') {
      <div class="d-flex">
        <div class="product-profile bg-secondary lg-radius shadow-sm">
          @if (product.lookup_image) {
            <img
              [src]="product.lookup_image"
              alt="{{ product.lookup_title }} Profile" />
          } @else {
            <span class="d-flex justify-content-center">
              <i class="fa-solid fa-mountain-sun fa-2x mt-4 text-secondary"></i>
            </span>
          }
        </div>
        <div class="col-6 ms-3 d-flex flex-column me-2 text-truncate">
          <b class="fs-medium text-truncate">{{ product.lookup_title }}</b>
          <span class="text-truncate text-secondary">{{
            product.lookup_subtitle
          }}</span>
          <small class="text-truncate">{{ product.lookup_description }}</small>
          @if (product.price) {
            <span class="fs-small fw-bold text-primary">
              <app-amount-visibility
                [alwaysShowAmount]="true"
                [amount]="product.price"
                [currency]="'BIF'"></app-amount-visibility>
            </span>
          }
        </div>
      </div>
    }
  </main>
}
